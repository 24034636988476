import React from 'react'

export const NoMatch = () => (
	<div>
	    <head>
			<title>404 ERROR</title>
		</head>
		<body>
		<header>
		<h1>404 ERROR</h1>

		<aside>Hey, you shouldn't be here!</aside>
		</header>

		<br/>

		<h2>Don't know how you got here but you should probably turn around and head back.</h2>

		<p>There's nothing here!</p>
		</body>
	</div>
  )